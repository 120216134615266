import React from 'react'

class Icons extends React.Component {
  render() {
    switch (this.props.name) {
      case 'cookie':
        return (
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 185 185"
            fill="currentColor"
            style={{ enableBackground: 'new 0 0 158 185' }}
            xmlSpace="preserve"
            className="cookieIcon"
          >
            <g>
              <g>
                <path d="M127.8,135.2c3.6,0,7-1.4,9.5-3.9c2.5-2.5,3.9-5.9,3.9-9.5c0-3.6-1.4-7-3.9-9.5c-2.5-2.5-5.9-3.9-9.5-3.9
                  c-3.6,0-7,1.4-9.5,3.9c-2.5,2.5-3.9,5.9-3.9,9.5c0,3.6,1.4,7,3.9,9.5C120.8,133.8,124.2,135.2,127.8,135.2z M127.8,128.7
                  c-1.9,0-3.6-0.7-4.9-2c-1.3-1.3-2-3-2-4.9c0-1.9,0.7-3.6,2-4.9c1.3-1.3,3.1-2,4.9-2c1.9,0,3.6,0.7,4.9,2c1.3,1.3,2,3,2,4.9
                  c0,1.9-0.7,3.6-2,4.9C131.4,128,129.6,128.7,127.8,128.7z"
                />
                <path d="M172.4,101.8c-0.7-2.7-2-5.1-3.8-7.3c-1.8-2.1-4-3.7-6.4-4.7l-0.2-0.1c-2.5-1-5.3-1.5-8.1-1.2
                  c-0.8,0.1-1.5,0.1-2.1,0.1c-0.5,0-1.2,0-2.1,0c-13.8,0-26.7-5.4-36.5-15.1C103.4,63.8,98,50.8,98,37c0-1.1,0-2,0.1-2.8
                  c0-0.9,0.1-1.8,0.2-2.8l0-0.4c0.2-2.7-0.1-5.3-1-7.8c-1-2.6-2.5-4.8-4.7-6.8c-2.1-1.9-4.5-3.3-7.2-4c-2.6-0.7-5.4-0.8-8.2-0.2
                  c-18.3,3.7-35,13.7-46.9,28.3c-12,14.6-18.3,32.4-18.3,51.4c0,21.7,8.4,42.1,23.8,57.4c15.3,15.3,35.7,23.8,57.4,23.8
                  c18.6,0,36.1-6.1,50.5-17.6c14.4-11.5,24.6-27.6,28.6-45.5C173.1,107.2,173.1,104.5,172.4,101.8z M80.9,18.4c1,0,1.9,0.1,2.9,0.4
                  c1.6,0.5,3.2,1.3,4.5,2.6c1.4,1.2,2.4,2.7,3,4.3c0.6,1.6,0.8,3.3,0.6,5.2c-0.1,1.1-0.2,2.1-0.2,3.1l-0.1,1.3c0,0.6,0,1.2,0,1.8
                  c0,15.5,6,30.1,17,41.1c11,11,25.6,17,41.1,17c0.6,0,1.4,0,2.4-0.1c0.9,0,1.7-0.1,2.4-0.1c1.9-0.1,3.6,0.1,5.1,0.7l0.2,0.1
                  c1.5,0.6,2.9,1.7,4,3c1.2,1.4,2,2.9,2.4,4.6c0.4,1.7,0.4,3.4,0,5.2c-3.8,16.4-13.1,31.3-26.4,41.8
                  c-13.3,10.6-29.4,16.2-46.5,16.2c-20,0-38.7-7.8-52.8-21.9c-14.1-14.1-21.9-32.9-21.9-52.8c0-17.2,6-34,16.8-47.3
                  c11-13.4,26.3-22.6,43.1-26C79.3,18.4,80.1,18.4,80.9,18.4z"
                />
                <path d="M37.7,102.1c-1.6,3-2,6.5-1.1,9.8c0.9,3.5,3.2,6.4,6.3,8.2l0.2,0.1c2,1.1,4.2,1.6,6.4,1.6
                  c1.2,0,2.3-0.2,3.5-0.5c3.5-0.9,6.3-3.2,8.1-6.3c1.8-3.1,2.3-6.7,1.3-10.2c-0.9-3.5-3.2-6.3-6.3-8.1c-3.1-1.8-6.8-2.3-10.2-1.3
                  c-3.4,0.9-6.3,3.1-8.1,6.2L37.7,102.1z M53,102.4c1.6,0.9,2.7,2.4,3.2,4.2c0.5,1.8,0.2,3.7-0.7,5.3l-0.3,0.5l-0.4,0.5
                  c-0.9,1.1-2.1,1.9-3.5,2.2c-1.6,0.4-3.4,0.3-5-0.6l0,0c-0.1-0.1-0.2-0.1-0.4-0.2l0,0c-1.5-0.9-2.6-2.4-3-4.1
                  c-0.5-1.7-0.3-3.5,0.6-5.1c0.1-0.1,0.1-0.2,0.2-0.4l0,0c0.9-1.5,2.4-2.5,4.1-3C49.5,101.3,51.4,101.5,53,102.4z"
                />
                <path d="M73.2,144.5c1.8,3.1,4.7,5.3,8.1,6.2c1.1,0.3,2.3,0.5,3.5,0.5c2.3,0,4.7-0.6,6.7-1.8
                  c3.1-1.8,5.3-4.7,6.3-8.1c0.9-3.5,0.4-7.1-1.3-10.2c-1.8-3.1-4.7-5.3-8.1-6.3c-3.4-0.9-7.1-0.4-10.2,1.3
                  c-3.1,1.8-5.3,4.7-6.3,8.1c-0.9,3.4-0.5,7,1.3,10.1L73.2,144.5z M83,144.4c-1.8-0.5-3.3-1.6-4.2-3.2c-0.9-1.6-1.2-3.5-0.7-5.3
                  c0.5-1.8,1.6-3.3,3.2-4.2c1-0.6,2.2-0.9,3.4-0.9c0.6,0,1.2,0.1,1.8,0.2c1.8,0.5,3.3,1.6,4.2,3.2c0.9,1.6,1.2,3.5,0.7,5.3
                  c-0.5,1.8-1.6,3.3-3.2,4.2C86.7,144.6,84.8,144.9,83,144.4z"
                />
                <path d="M57.2,76.7c3.6,0,7-1.4,9.5-3.9c2.5-2.5,3.9-5.9,3.9-9.5c0-3.6-1.4-7-3.9-9.5c-2.5-2.5-5.9-3.9-9.5-3.9
                  c-3.6,0-6.9,1.4-9.5,3.9c-2.5,2.5-3.9,5.9-3.9,9.5c0,3.6,1.4,6.9,3.9,9.5C50.2,75.3,53.6,76.7,57.2,76.7z M57.2,56.3
                  c1.9,0,3.6,0.7,4.9,2c1.3,1.3,2,3.1,2,4.9c0,1.9-0.7,3.6-2,4.9c-1.3,1.3-3,2-4.9,2c-1.9,0-3.6-0.7-4.9-2c-1.3-1.3-2-3.1-2-4.9
                  c0-1.9,0.7-3.6,2-4.9C53.6,57,55.3,56.3,57.2,56.3z"
                />
              </g>
            </g>
          </svg>
        )

      case 'video':
        return (
          <svg className="videoIcon" viewBox="0 0 122.88 111.34" fill="currentColor">
            <path d="M23.59,0h75.7a23.68,23.68,0,0,1,23.59,23.59V87.75A23.56,23.56,0,0,1,116,104.41l-.22.2a23.53,23.53,0,0,1-16.44,6.73H23.59a23.53,23.53,0,0,1-16.66-6.93l-.2-.22A23.46,23.46,0,0,1,0,87.75V23.59A23.66,23.66,0,0,1,23.59,0ZM54,47.73,79.25,65.36a3.79,3.79,0,0,1,.14,6.3L54.22,89.05a3.75,3.75,0,0,1-2.4.87A3.79,3.79,0,0,1,48,86.13V50.82h0A3.77,3.77,0,0,1,54,47.73ZM7.35,26.47h14L30.41,7.35H23.59A16.29,16.29,0,0,0,7.35,23.59v2.88ZM37.05,7.35,28,26.47H53.36L62.43,7.38v0Zm32,0L59.92,26.47h24.7L93.7,7.35Zm31.32,0L91.26,26.47h24.27V23.59a16.32,16.32,0,0,0-15.2-16.21Zm15.2,26.68H7.35V87.75A16.21,16.21,0,0,0,12,99.05l.17.16A16.19,16.19,0,0,0,23.59,104h75.7a16.21,16.21,0,0,0,11.3-4.6l.16-.18a16.17,16.17,0,0,0,4.78-11.46V34.06Z" />
          </svg>
        )

      default:
        return null
    }
  }
}

export default Icons
