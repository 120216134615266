import React, { Component } from 'react'

function getTimeOfDay() {
  const date = new Date()
  const currentHour = date.getHours()
  let timeOfDay = 'evening'
  if (currentHour > 1 && currentHour < 12) {
    timeOfDay = 'morning'
  } else if (currentHour > 11 && currentHour < 17) {
    timeOfDay = 'afternoon'
  }
  return timeOfDay
}

class HomePageGreeting extends Component {
  render() {
    const { authorizations, pendingAuthorization } = this.props
    const timeOfDay = getTimeOfDay()
    let singleStudentName
    let multipleStudentNames = false
    authorizations.forEach((authorization, index) => {
      if (index === 0) {
        singleStudentName = authorization.student.firstName
      } else if (singleStudentName !== authorization.student.firstName) {
        multipleStudentNames = true
      }
    })

    return (
      <div className="homePage-greeting">
        {/* PRE AUTHORIZED */}
        {authorizations.length === 0 && !pendingAuthorization && (
        <React.Fragment>
          <div className="homePage-greeting-primary">
            There are no classes registered to this device yet
          </div>
          <div className="homePage-greeting-secondary">
            Click the secure link sent to you by your teacher to register to your class
          </div>
        </React.Fragment>
        )}

        {/* HAS AUTHORIZED SECTION COUNT */}
        {authorizations.length !== 0 && !multipleStudentNames && singleStudentName && (
        <React.Fragment>
          <div className={`homePage-greeting-primary ${pendingAuthorization && authorizations.length === 1 ? 'homePage-greeting-primary--registeringTest' : ''}`}>
            Good
            {' '}
            {timeOfDay}
            ,
            {singleStudentName}
          </div>
          <div className={`homePage-greeting-secondary ${pendingAuthorization && authorizations.length === 1 ? 'homePage-greeting-secondary--registeringTest' : ''}`}>
            You can wait here for your class to start.
          </div>
        </React.Fragment>
        )}
        {/* HAS AUTHORIZED SECTION COUNT */}
        {authorizations.length !== 0 && multipleStudentNames && (
        <React.Fragment>
          <div className="homePage-greeting-primary">
            Good
            {' '}
            {timeOfDay}
          </div>
          <div className="homePage-greeting-secondary">
            You can wait here for your class to start.
          </div>
        </React.Fragment>
        )}
      </div>
    )
  }
}

export default HomePageGreeting
